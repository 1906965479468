.m-4 {
    max-width: 600px;
    margin: 4rem auto;
  
    h2 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  
    form {
      .mb-3 {
        margin-bottom: 1.5rem;
  
        label {
          display: block;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
  
        input,
        textarea {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
        }
  
        textarea {
          resize: vertical;
        }
      }
  
      .field.buffer {
        margin-bottom: 1.5rem;
      }
  
      button {
        display: inline-block;
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: #fff;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  