/* attachments shortcode */

section.attachments {
  margin: 2rem 0;
  position: relative;
}

section.attachments label {
  font-weight: 400;
  padding-left: 0.5em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  margin: 0;
}

section.attachments .attachments-files {
  padding: 15px;
  display: block;
  font-size: 1rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  color: #666;
}

section.attachments.orange label {
  color: #fff;
  background: #F0B37E;
}

section.attachments.orange .attachments-files {
  background: #FFF2DB;
}

section.attachments.green label {
  color: #fff;
  background: rgba(92, 184, 92, 0.8);
}

section.attachments.green .attachments-files {
  background: #E6F9E6;
}

section.attachments.blue label {
  color: #fff;
  background: #6AB0DE;
}

section.attachments.blue .attachments-files {
  background: #E7F2FA;
}

section.attachments.grey label {
  color: #fff;
  background: #505d65;
}

section.attachments.grey .attachments-files {
  background: #f4f4f4;
}

.full-screen-link {
  display: inline-block;
  padding: 5px 10px;
  background-color: #E6F9E6; /* Change the color to your preference */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #00b351; /* Change the hover color to your preference */
  }
}
